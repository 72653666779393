import { ArtistCard, ArtistCardProps } from 'components/artists/ArtistCard'
import { Carousel } from 'components/carousels/Carousel'
import { Skeletons } from 'components/common/Skeletons'
import useTranslation from 'next-translate/useTranslation'

interface TrendingArtistsCarouselProps {
  trendingArtists?: ArtistCardProps[] | null
}

/**
 * As of April 2024:
 *
 * Trending artists should be updated once every hour.
 *
 * The data is fetched on the server `pagex/index.tsx/getServerSideProps` and cached for an hour.
 *
 * Anouther request here would be useless and would only impact performance, so don't do it.
 */
export const TrendingArtistsCarousel = ({
  trendingArtists,
}: TrendingArtistsCarouselProps) => {
  const { t } = useTranslation()

  return !trendingArtists || trendingArtists?.length ? (
    <Carousel
      title={t('common:artists')}
      className='bg-cool-150 py-6 md:py-8'
      viewAllHref='/artists'
      viewAllLabel={t('common:view_all')}
    >
      {trendingArtists?.map((artist, index) => (
        <ArtistCard key={index} {...artist} />
      ))}
      {!trendingArtists && <Skeletons type='artist' />}
    </Carousel>
  ) : null
}
