import classNames from 'classnames'
import { ContainerElement } from 'types'

export function CategoriesContainer({ className, children }: ContainerElement) {
  return (
    <ul
      className={classNames(
        'grid w-full grid-cols-1 justify-items-center gap-x-4 gap-y-4  xs:grid-cols-2 xs:grid-rows-2 lg:grid-cols-4 lg:grid-rows-1',
        className
      )}
    >
      {children}
    </ul>
  )
}
