import { MutableRefObject, useEffect, useMemo, useState } from 'react'

export type Config = {
  rootMargin?: number
  threshold?: number | number[]
}

/**
 * Detects when a component is on screen or almost in screen, depeding on the given `config`.
 *
 * @link https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API#intersection_observer_concepts_and_usage
 */
export const useOnScreen = (
  ref: MutableRefObject<HTMLDivElement | null>,
  { rootMargin = 80, threshold = 0.1 }: Config = {}
) => {
  const [isOnScreen, setIsOnScreen] = useState(false)

  const observer = useMemo(() => {
    if (typeof IntersectionObserver === 'undefined') return null

    return new IntersectionObserver(
      entries => {
        setIsOnScreen(entries.some(entry => entry.isIntersecting))
      },
      { rootMargin: `${rootMargin}px`, threshold }
    )
  }, [rootMargin, threshold])

  useEffect(() => {
    if (!ref.current || !observer) return

    const target = ref.current

    observer.observe(target)

    return () => {
      observer.disconnect()
    }
  }, [ref, observer])

  return { isOnScreen, observer }
}
