import classNames from 'classnames'
import { BannerImage } from 'components/common/BannerImage'
import { Button } from 'components/common/Button'
import { BannerProps } from 'types'

export function BannerBlurred({
  src,
  alt,
  children,
  className,
  loading,
  priority,
  quality = 40,
}: BannerProps) {
  return (
    <div
      className={classNames(
        'relative bg-cover bg-center bg-no-repeat py-20',
        className
      )}
    >
      <div className='absolute left-0 top-0 h-full w-full'>
        <BannerImage
          src={src}
          alt={alt}
          priority={priority}
          loading={loading}
          quality={quality}
        />
      </div>

      <div className='container'>
        <div className='container flex w-fit flex-col items-center rounded-xl bg-cool-50 bg-opacity-80 px-12 py-8 backdrop-blur-2xl'>
          {!children && (
            <>
              <p className='mb-2 text-center text-lg text-cool-700'>
                What are you waiting for?
              </p>
              <h5 className='text-center font-medium text-cool-900 lg:hidden'>
                Buy & Sell from 1000s of artworks
              </h5>
              <h4 className='hidden whitespace-nowrap text-center font-medium text-cool-900 lg:block'>
                Buy & Sell from 1000s of artworks
              </h4>
              <Button size='lg' type='link' href='/artworks' className='mt-8'>
                View all artworks
              </Button>
            </>
          )}
          {children}
        </div>
      </div>
    </div>
  )
}
