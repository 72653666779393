/* eslint-disable indent */
import { BannerHalfBlurred } from 'components/common/BannerHalfBlurred'
import { useEmblaPagination } from 'contexts'
import { EmblaPaginationProvider } from 'contexts/providers/EmblaPaginationProvider'
import useEmblaCarousel from 'embla-carousel-react'
import useTranslation from 'next-translate/useTranslation'
import React, {
  ComponentProps,
  PropsWithChildren,
  memo,
  useEffect,
  useState,
} from 'react'

type Slide = {
  content: React.ReactNode
} & Omit<
  ComponentProps<typeof BannerHalfBlurred>,
  'type' | 'isFocused' | 'renderImageWhenOnScreen'
>

interface BannerCarouselProps {
  slides: Slide[]
}

export const BannerCarousel = memo(({ slides }: BannerCarouselProps) => {
  const [slidesInView, setSlidesInView] = useState<number[]>([0])

  const [carousel, carouselApi] = useEmblaCarousel(
    {
      loop: true,
      direction: 'ltr',
      inViewThreshold: 1,
    },
    []
  )

  useEffect(() => {
    if (!carouselApi) return

    const updateSlidesInView = () => {
      setSlidesInView(slidesInView => {
        if (slidesInView.length === carouselApi.slideNodes().length) {
          carouselApi.off('slidesInView', updateSlidesInView)
        }

        const inView = carouselApi
          .slidesInView()
          .filter(index => !slidesInView.includes(index))

        return slidesInView.concat(inView)
      })
    }

    updateSlidesInView()

    carouselApi.on('slidesInView', updateSlidesInView)
    carouselApi.on('reInit', updateSlidesInView)
  }, [carouselApi])

  return (
    <div className='relative w-full'>
      <div ref={carousel} className='w-full overflow-hidden'>
        <div
          className='flex touch-pan-y'
          style={{ backfaceVisibility: 'hidden' }}
        >
          {slides.map(({ content, ...slide }, index) => {
            return (
              <div key={index} className='min-w-0 flex-[0_0_100%]'>
                <BannerHalfBlurred
                  loading={
                    index === 0
                      ? 'eager'
                      : slidesInView.includes(index)
                      ? 'eager'
                      : 'lazy'
                  }
                  {...slide}
                >
                  {content}
                </BannerHalfBlurred>
              </div>
            )
          })}
        </div>
      </div>

      <div className='absolute bottom-2 mx-auto flex w-full justify-center gap-x-4 sm:bottom-4'>
        <EmblaPaginationProvider emblaApi={carouselApi}>
          <DotButtons />
        </EmblaPaginationProvider>
      </div>
    </div>
  )
})

BannerCarousel.displayName = 'BannerCarousel'

const DotButtons = () => {
  const { scrollSnaps, selectedIndex, onPaginate } = useEmblaPagination()

  return (
    <>
      {scrollSnaps.map((_, index) => (
        <DotButton
          key={index}
          onClick={() => onPaginate(index)}
          selected={index === selectedIndex}
          index={index}
        />
      ))}
    </>
  )
}

type PropType = PropsWithChildren<
  React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  >
> & { selected: boolean; index: number }

const DotButton: React.FC<PropType> = props => {
  const { children, selected, index, ...restProps } = props
  const { t } = useTranslation('home')

  return (
    <button
      type='button'
      {...restProps}
      className={[
        'h-[3px] w-5 rounded sm:h-3.5 sm:w-3.5 sm:rounded-full',
        selected ? 'bg-cool-50 sm:bg-cool-900' : 'bg-cool-50/25 sm:bg-cool-50',
      ].join(' ')}
    >
      <p className='sr-only'>{t('view_slide_x', { x: index + 1 })}</p>

      {children}
    </button>
  )
}
