import { Carousel } from 'components/carousels/Carousel'
import useTranslation from 'next-translate/useTranslation'
import { PropsWithChildren } from 'react'

export function LatestArtworksSalesContainer({ children }: PropsWithChildren) {
  const { t } = useTranslation()

  return (
    <Carousel title={t('common:latest_sales')} className='py-6 md:py-8'>
      {children}
    </Carousel>
  )
}
