import classNames from 'classnames'
import { ContainerElement } from 'types'

export function BannerContainer({ className, children }: ContainerElement) {
  return (
    <div
      className={classNames(
        'relative flex h-[350px] w-full sm:h-[300px]',
        className
      )}
    >
      {children}
    </div>
  )
}
