import { Carousel } from 'components/carousels/Carousel'
import useTranslation from 'next-translate/useTranslation'
import { PropsWithChildren } from 'react'

export function LatestArtworksBidsContainer({ children }: PropsWithChildren) {
  const { t } = useTranslation()

  return (
    <Carousel
      title={t('home:latest_bids')}
      className='py-6 md:py-8'
      viewAllHref='/artworks?sortBy=Recommended&availablities=Sell+now'
      viewAllLabel={t('common:view_all')}
    >
      {children}
    </Carousel>
  )
}
