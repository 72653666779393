import { Carousel } from 'components/carousels/Carousel'
import routes from 'constants/routes'
import useTranslation from 'next-translate/useTranslation'
import { PropsWithChildren } from 'react'

export function ReleasesContainer({ children }: PropsWithChildren) {
  const { t } = useTranslation()

  return (
    <Carousel
      title={t('common:release_calendar')}
      className='py-6 md:py-8'
      viewAllLabel={t('common:view_all')}
      viewAllHref={routes.releaseCalendar.base}
      grid={{
        md: 'md:w-[calc(33.33%_-_2_/_3_*_theme(margin.4))]',
        lg: 'lg:w-[calc(25%_-_3_/_4_*_theme(margin.4))]',
        xl: 'xl:w-[calc(20%_-_4_/_5_*_theme(margin.4))]',
      }}
    >
      {children}
    </Carousel>
  )
}
