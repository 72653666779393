import { Config, useOnScreen } from 'hooks/useOnScreen'
import { ReactNode, useEffect, useRef, useState } from 'react'

type Props = {
  children: ReactNode
  className?: string
  fallback?: ReactNode
} & Config

/**
 * Offsets the `children` component rendering until the `fallback` component is on screen or almost on screen (depending on the given `config`.).
 */
export const OnScreenComponent = ({
  children,
  className,
  fallback,
  ...config
}: Props) => {
  const ref = useRef<HTMLDivElement | null>(null)
  const { isOnScreen, observer } = useOnScreen(ref, { ...config })
  const [isRefOnScreen, setIsRefOnScreen] = useState(false)

  useEffect(() => {
    if (!isRefOnScreen && isOnScreen) {
      setIsRefOnScreen(isOnScreen)
      observer?.disconnect()
    }
  }, [isOnScreen, observer])

  return (
    <div
      ref={ref}
      className={className}
      data-on-screen-component
      data-loaded={isRefOnScreen}
    >
      {isRefOnScreen && children}
      {!isRefOnScreen && fallback}
    </div>
  )
}
