import classNames from 'classnames'
import Image from 'next/image'
import { BannerImageProps, PropsWithClassName } from 'types'

type Props = BannerImageProps & PropsWithClassName

export function BannerImage({
  className,
  loading = 'lazy',
  priority = false,
  src,
  alt = '',
  quality,
  sizes,
}: Props) {
  return (
    <div className={classNames('relative h-full object-cover', className)}>
      <Image
        loading={loading}
        fetchPriority={priority ? 'high' : 'low'}
        src={src}
        alt={alt}
        fill
        role='presentation'
        className='max-w-full object-cover'
        sizes={sizes}
        quality={quality}
      />
    </div>
  )
}
