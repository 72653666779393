import { OnScreenComponent } from 'components/abstract/OnScreenComponent'
import { Header } from 'components/common/Header'
import { useRouter } from 'next/router'
import { lazy, Suspense } from 'react'
import { tw } from 'utils/strings'

const Footer = lazy(() =>
  import('components/common/Footer').then(m => ({ default: m.Footer }))
)

type Props = {
  hasHeaderSeparator?: boolean
  hasFooter?: boolean
  children: React.ReactNode
  style?:
    | 'pure-white'
    | 'gray-header'
    | 'off-white-header'
    | 'off-white-bg'
    | 'off-white'
    | 'warm-bg'
    | 'primary-light'
    | 'ivory'
}

export const BaseLayout = ({
  hasHeaderSeparator = true,
  hasFooter = true,
  children,
  style = 'off-white-header',
}: Props) => {
  const router = useRouter()

  const styles = {
    'pure-white': {
      header: tw`bg-cool-50`,
      bg: tw`bg-cool-50`,
    },
    'gray-header': {
      header: tw`bg-cool-200`,
      bg: tw`bg-cool-50`,
    },
    'off-white-header': {
      header: tw`bg-cool-150`,
      bg: tw`bg-cool-50`,
    },
    'off-white-bg': {
      header: tw`bg-cool-50`,
      bg: tw`bg-cool-150`,
    },
    'off-white': {
      header: tw`bg-cool-150`,
      bg: tw`bg-cool-150`,
    },
    'warm-bg': {
      header: tw`bg-cool-50`,
      bg: tw`bg-warm-100`,
    },
    'primary-light': {
      header: tw`bg-cool-150`,
      bg: tw`bg-primary-light bg-opacity-20`,
    },
    ivory: {
      header: tw`bg-cool-50`,
      bg: tw`bg-ivory`,
    },
  }

  return (
    <div className={`flex min-h-screen flex-col ${styles[style]['bg']}`}>
      <div
        className={`top-0 z-50 w-full ${styles[style]['header']} ${
          router.pathname === '/artworks' ? 'static' : 'sticky'
        }`}
      >
        <Header
          actions='auth'
          hasNav
          hasSeparator={hasHeaderSeparator}
          offWhite={
            style === 'off-white' ||
            style === 'off-white-bg' ||
            style === 'gray-header'
          }
        />
      </div>
      {children}

      {hasFooter && (
        <OnScreenComponent>
          <Suspense fallback={null}>
            <Footer />
          </Suspense>
        </OnScreenComponent>
      )}
    </div>
  )
}
