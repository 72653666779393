import { Carousel } from 'components/carousels/Carousel'
import routes from 'constants/routes'
import useTranslation from 'next-translate/useTranslation'
import { PropsWithChildren } from 'react'

export function PrintsContainer({ children }: PropsWithChildren) {
  const { t } = useTranslation()

  return (
    <Carousel
      title={t('common:prints')}
      className='py-6 md:py-8'
      viewAllLabel={t('common:view_all')}
      viewAllHref={`${routes.artworks}?medium=Print`}
    >
      {children}
    </Carousel>
  )
}
