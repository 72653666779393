import { BannerBlurred } from 'components/common/BannerBlurred'
import { Button } from 'components/common/Button'
import { BaseLayout } from 'layouts/BaseLayout'
import useTranslation from 'next-translate/useTranslation'

type Props = {
  children: React.ReactNode
}

export const HomeLayout = ({ children }: Props) => {
  return (
    <BaseLayout style='pure-white'>
      {children}

      <BannerBlurred
        src='/files/banners/artworks-bg2-big.jpg'
        priority={false}
        loading='lazy'
      >
        <BannerContent />
      </BannerBlurred>
    </BaseLayout>
  )
}

function BannerContent() {
  const { t } = useTranslation('home')

  return (
    <>
      <p className='mb-2 text-lg text-cool-700'>{t('unsure')}</p>
      <h5 className='text-center font-medium text-cool-900 lg:hidden'>
        {t('get_inspired')}
      </h5>
      <h4 className='hidden whitespace-nowrap text-center font-medium text-cool-900 lg:block'>
        {t('get_inspired')}
      </h4>
      <Button size='lg' type='link' href='/artworks' className='mt-8'>
        {t('view_all_artworks')}
      </Button>
    </>
  )
}
