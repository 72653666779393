/* eslint-disable indent */
import classNames from 'classnames'
import { BannerContainer } from 'components/common/BannerContainer'
import { BannerImage } from 'components/common/BannerImage'
import { memo } from 'react'
import Skeleton from 'react-loading-skeleton'
import { BannerProps } from 'types'

export const ImgSkeleton = () => (
  <Skeleton width='100%' height='100%' className='absolute left-0 top-0' />
)

type Props = { reversed?: boolean; imageClassName?: string } & BannerProps

export const BannerHalfBlurred = memo(
  ({
    className,
    children,
    src,
    alt,
    reversed,
    loading,
    priority,
    quality = 65,
    sizes = '(max-width: 639px) 80vw, 50vw',
    imageClassName = 'sm:w-1/2',
  }: Props) => {
    return (
      <BannerContainer
        className={classNames(
          'flex-col',
          reversed ? 'sm:flex-row-reverse' : 'sm:flex-row',
          className
        )}
      >
        <BannerImage
          src={src}
          alt={alt}
          loading={loading}
          priority={priority}
          quality={quality}
          className={imageClassName}
          sizes={sizes}
        />

        <div className='hidden w-1/2 bg-cool-300 sm:block'></div>

        <div className='absolute bottom-0 mx-auto hidden h-full w-full sm:block'>
          <div
            className={[
              'container flex h-full',
              reversed ? 'flex-row' : 'flex-row-reverse',
            ].join(' ')}
          >
            <div
              className={[
                'bg-white/10 flex h-full w-1/2 flex-col justify-center pb-6 pt-4',
                reversed ? 'pr-10' : 'px-10',
              ].join(' ')}
            >
              {children}
            </div>
          </div>
        </div>

        <div className='absolute bottom-0 w-full sm:hidden'>
          <div className='flex h-full w-full flex-col justify-center bg-cool-700/30 px-6 pb-8 pt-4 backdrop-blur-[30px]'>
            {children}
          </div>
        </div>
      </BannerContainer>
    )
  }
)

BannerHalfBlurred.displayName = 'BannerHalfBlurred'
