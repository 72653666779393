import Head from 'next/head'
import { ReactNode } from 'react'

type Props = {
  pageTitle: string
  pageDescription: string
  imageName?: string
  relativeUrl?: string
  children?: ReactNode
}

export const Meta = ({
  pageTitle,
  pageDescription,
  imageName,
  relativeUrl = '',
  children,
}: Props) => {
  return (
    <Head>
      <title>{pageTitle}</title>
      <meta name='description' content={pageDescription} />

      <meta name='og:title' content={pageTitle} />
      <meta name='og:description' content={pageDescription} />

      {imageName && (
        <meta name='og:image' content={`/files/og/${imageName}.jpg`} />
      )}

      {relativeUrl && (
        <link
          rel='canonical'
          href={`${process.env.NEXT_PUBLIC_SITE_URL}${relativeUrl}`}
        />
      )}

      {children}
    </Head>
  )
}
