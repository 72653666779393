/* eslint-disable jsx-a11y/alt-text */
import classNames from 'classnames'
import { OnScreenComponent } from 'components/abstract/OnScreenComponent'
import { useLoading } from 'contexts'
import useTranslation from 'next-translate/useTranslation'
import Image from 'next/image'
import Link from 'next/link'
import { ComponentProps, MouseEvent, useState } from 'react'
import Skeleton from 'react-loading-skeleton'
import { isNavigationClickEvent } from 'utils/interactions'

export type ArtistCardProps = {
  className?: string
  id?: number
  src: string
  name: string
  nationality?: string
  birthDate?: string
  href?: string
  onLinkClick?: () => void
  additionalSrc?: {
    medium?: string
  }
}

export const ArtistCard = ({
  className,
  src,
  name,
  nationality,
  birthDate,
  href = '#',
  onLinkClick,
  additionalSrc,
}: ArtistCardProps) => {
  const { t } = useTranslation()
  const [imgLoaded, setImgLoaded] = useState(false)
  const { setStateHandler: setGlobalLoadingStateHandler } = useLoading()

  const onClick = (event: MouseEvent<HTMLAnchorElement>) => {
    // if (isNavigationClickEvent(event)) {
    //   setGlobalLoadingStateHandler({ isArtistPage: true, isLoading: true })
    // }

    onLinkClick?.()
  }

  const alt = `${t('common:picture_of_artist')} ${name}`

  const imgProps: ComponentProps<typeof Image> = {
    src,
    alt,
    sizes: '20vw',
    quality: 75,
    onLoadingComplete: () => setImgLoaded(true),
  }

  return (
    <Link
      title={name}
      href={href}
      prefetch={false}
      className={`group flex flex-col [&_*]:pointer-events-none relative ${className}`}
      onClick={onClick}
      data-e2e='artist-card-link'
    >
      <OnScreenComponent
        className='relative h-full w-full duration-300 ease-in-out group-hover:brightness-75'
        fallback={
          <div className='aspect-1'>
            <Skeleton className='absolute h-full w-full' />
          </div>
        }
      >
        {!imgLoaded && (
          <div className='absolute left-0 top-0 z-[1] h-full w-full'>
            <Skeleton className='absolute h-full w-full' />
          </div>
        )}

        {additionalSrc?.medium && (
          <Image
            {...imgProps}
            src={additionalSrc.medium}
            width={324}
            height={324}
            className='hidden aspect-1 h-full w-full rounded object-cover object-center lg:block'
          />
        )}

        <Image
          {...imgProps}
          width={324}
          height={324}
          className={classNames(
            'hidden aspect-1 h-full w-full rounded object-cover object-center md:block',
            additionalSrc?.medium && 'lg:hidden'
          )}
        />

        <Image
          {...imgProps}
          width={290}
          height={290}
          className='aspect-1 rounded object-cover object-center md:hidden'
        />
      </OnScreenComponent>

      <div className='mt-3'>
        <p className='overflow-hidden text-ellipsis whitespace-nowrap text-sm font-medium sm:text-base'>
          {name}
        </p>
        <span className='block overflow-hidden text-ellipsis whitespace-nowrap text-sm text-cool-600'>
          {nationality && <>{nationality}</>}
          {birthDate && (
            <>
              {nationality && <>,</>} b. {birthDate}
            </>
          )}
        </span>
      </div>
    </Link>
  )
}
